import React from 'react'
import LoginComp from '../../components/login-comp';

const Login = () => {
  return (
    <>
        <LoginComp />
    </>
  )
}

export default Login;